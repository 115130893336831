<template>
  <div class="container">
    <div class="heading-primary u-margin-top-medium u-text-align-center">
      Privacy Policy
    </div>
    <div class="box paragraph">
      <p>
        At IGotStandardsBro.com one of our main priorities is the privacy of our
        visitors. This Privacy Policy document contains types of information
        that is collected and recorded by IGotStandardsBro.com and how we use
        it.
      </p>

      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us through the twitter link
        below.
      </p>

      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in IGotStandardsBro.com. This policy is not
        applicable to any information collected offline or via channels other
        than this website.
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h2>Information we collect</h2>

      <p>
        We do not collect your browsing data nor personal data (i.e. name, email
        address, phone number). We do not share your data directly with anyone.
        The services we use may collect and use your data anonymously directly.
      </p>

      <h2>Analytics</h2>

      <p>We use Google Analytics to track our website statistics.</p>

      <h2>Log Files</h2>

      <p>
        IGotStandardsBro.com follows a standard procedure of using log files.
        These files log visitors when they visit websites. All hosting companies
        do this and a part of hosting services' analytics. The information
        collected by log files include internet protocol (IP) addresses, browser
        type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not
        linked to any information that is personally identifiable. The purpose
        of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic
        information.
      </p>

      <h2>Cookies and Web Beacons</h2>

      <p>
        Like any other website, IGotStandardsBro.com uses 'cookies'. These
        cookies are used to store information including visitors' preferences,
        and the pages on the website that the visitor accessed or visited. The
        information is used to optimize the users' experience by customizing our
        web page content based on visitors' browser type and/or other
        information.
      </p>

      <h2>Google DoubleClick DART Cookie</h2>

      <p>
        Google is one of a third-party vendor on our site. It also uses cookies,
        known as DART cookies, to serve ads to our site visitors. However,
        visitors may choose to decline the use of DART cookies by visiting the
        Google ad and content network Privacy Policy at the following URL -
        <a
          class="button-text-accent"
          href="https://policies.google.com/technologies/ads"
          >https://policies.google.com/technologies/ads</a
        >
      </p>

      <h2>Our Advertising Partners</h2>

      <p>
        Third-party ad servers or ad networks use technologies like cookies,
        JavaScript, or Web Beacons that are used in their respective
        advertisements and links that appear on IGotStandardsBro.com, which are
        sent directly to users' browser. They automatically receive your IP
        address when this occurs. These technologies are used to measure the
        effectiveness of their advertising campaigns and/or to personalize the
        advertising content that you see on websites that you visit.
      </p>

      <p>
        Note that IGotStandardsBro.com has no access to or control over these
        cookies that are used by third-party advertisers.
      </p>

      <h2>Third Party Privacy Policies</h2>

      <p>
        IGotStandardsBro.com's Privacy Policy does not apply to other
        advertisers or websites. Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers for more
        detailed information. It may include their practices and instructions
        about how to opt-out of certain options.
      </p>

      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers' respective
        websites.
      </p>

      <h2>Children's Information</h2>

      <p>
        Another part of our priority is adding protection for children while
        using the internet. We encourage parents and guardians to observe,
        participate in, and/or monitor and guide their online activity.
      </p>

      <h2 class="u-margin-top-medium">Ezoic Privacy Policy</h2>
      <p>
        Click here to read the
        <a class="button-text-accent" href="https://www.ezoic.com/privacy-policy/">Ezoic Privacy Policy</a>.
      </p>
    </div>
    <!-- Ezoic - privacy_bottom_of_page - bottom_of_page -->
    <div id="ezoic-pub-ad-placeholder-108"></div>
    <!-- End Ezoic - privacy_bottom_of_page - bottom_of_page -->
  </div>
</template>

<script>
import ezoic from '@/utils/ezoic';

export default {
  name: 'Privacy',

  mounted() {
    // update ezoic ads for this page
    ezoic.updateAds([108]);
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 2rem;
}

h1 {
  @extend .heading-secondary;
  margin-top: 6rem;
}

h2 {
  @extend .heading-tertiary;
}

h3 {
  @extend .paragraph-bold;
  margin-top: 3rem;
}

li {
  margin-left: 4rem;
}
</style>
